var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppLayout',{attrs:{"wrapperClass":"wishlist"}},[_c('Breadcrumbs'),_c('section',{staticClass:"container"},[_c('div',{staticClass:"title-wrapper"},[_c('h1',{staticClass:"title"},[_vm._v(" Wunschliste ")]),_c('h6',{staticClass:"sub-title"},[_vm._v(" Eine Übersicht Ihrer Wunschliste ")])]),_c('div',[(_vm.wishListItems.length === 0)?_c('div',{staticClass:"empty-wishlist"},[_c('p',[_vm._v("Your wishlist is empty. Add some products to it!")])]):_c('div',{staticClass:"product-section-wrapper border-top"},[_c('div',{staticClass:"product-wrapper row"},_vm._l((_vm.wishListItems),function(product,index){return _c('div',{key:product.id || index,staticClass:"col-xl-3 col-lg-4 col-sm-6 col-12 mb-40"},[_c('ProductCard',{attrs:{"product":product.product,"isWishlistPage":true}})],1)}),0)]),_c('div',[_c('b-link',{staticClass:"btn btn-warning d-block"},[_vm._v(" "+_vm._s(_vm.$t("Befehl")))]),_c('span',{staticClass:"h2 title"},[_vm._v("Zu Ihrer Liste hinzufügen!")])],1),_c('div',[(_vm.crossSellProducts.length > 4)?_c('div',{staticClass:"product-section-wrapper"},[_c('VueSlickCarousel',{staticClass:"product-wrapper",attrs:{"dots":false,"arrows":true,"slidesToShow":4,"slidesToScroll":1,"infinite":true,"responsive":[
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1
                }
              }
            ]}},_vm._l((_vm.crossSellProducts),function(product,index){return _c('ProductCard',{key:index,attrs:{"product":product}})}),1)],1):_c('div',{staticClass:"product-section-wrapper"},[_c('div',{staticClass:"product-wrapper row"},_vm._l((_vm.crossSellProducts),function(product,index){return _c('ProductCard',{key:index,staticClass:"col-xl-3 col-lg-4 col-sm-6 col-12 mb-40",attrs:{"product":product}})}),1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }