<template>
  <div>
    <b-modal id="modal-dynamic_form" okVariant="success" okTitle="Ok" @ok="clickDynFormOk">
      <div v-html="msg"></div>
    </b-modal>

    <h2>{{ form.title }}</h2>

    <div v-for="(set, idx) in form.fieldsets" :key="`set-${idx}`" :class="formWrapperClass">
      <!-- <h3>{{ set.title }}</h3> -->

      <div v-if="idx == 0" class="form-row">
        <div v-for="(field, index) in set.fields.slice(0, 3)" :key="`field-${index}`" class="col-md-4">
          <FormInput :key="`input-${index}`" :field="field" :idx="idx" :index="index" v-model="formValue[idx][index]"
            :state="formState[idx][index]" :error="formError[idx][index]"
            @update="updateFormState(idx, index, $event)" />
        </div>
      </div>

      <div v-if="idx == 0" class="form-row">
        <div v-for="(field, index) in set.fields.slice(3, 5)" :key="`field-${index}`" class="col-md-6">
          <FormInput :key="`input-${index}`" :field="field" :idx="idx" :index="index" v-model="formValue[idx][index]"
            :state="formState[idx][index]" :error="formError[idx][index]"
            @update="updateFormState(idx, index, $event)" />
        </div>
      </div>

      <div v-if="idx == 0">
        <div v-for="(field, index) in set.fields.slice(5)" :key="`field-${index}`">
          <FormInput :key="`input-${index}`" :field="field" :idx="idx" :index="index" v-model="formValue[idx][index]"
            :state="formState[idx][index]" :error="formError[idx][index]"
            @update="updateFormState(idx, index, $event)" />
        </div>
      </div>

      <div v-if="idx > 0">
        <div v-for="(field, index) in set.fields" :key="`field-${index}`">
          <FormInput :key="`input-${index}`" :field="field" :idx="idx" :index="index" v-model="formValue[idx][index]"
            :state="formState[idx][index]" :error="formError[idx][index]"
            @update="updateFormState(idx, index, $event)" />
        </div>
      </div>
    </div>

    <b-button @click="sendForm" type="button" variant="info">{{ $t('send') }}</b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { sendForm } from '@storefront/core/data-resolver/forms';
import FormInput from './FormInput.vue'; // Assuming you create a reusable component for form inputs

export default {
  name: 'DynamicForms',
  props: {
    category_id: {
      type: Number,
      required: false,
    },
    product_sku: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    formWrapperClass: {
      type: String,
    },

  }, components: {
    FormInput
  },
  data() {
    return {
      formValue: [],
      formState: [],
      formError: [],
      msg: '',
    };
  },
  computed: {
    ...mapGetters({
      product: 'product/getCurrentProduct',
      category: 'category/getCurrentCategory',
    }),
    remote_entity_id() {
      if (this.type === 'product') {
        return `remote_entity_id: "${this.product_sku}"`;
      } else if (this.type === 'category') {
        return `remote_entity_id: "${this.category_id}"`;
      }
      return '';
    },
    form() {
      if (this.type === 'product') {
        return this.$store.getters['forms/getProductForm'];
      } else if (this.type === 'category') {
        return this.$store.getters['forms/getCategoryForm'];
      }
      return this.$store.getters['forms/getContactForm'];
    },
  },
  watch: {
    product() {
      if (this.type === 'product') {
        this.resetFormValues();
      }
    },
    category() {
      if (this.type === 'category') {
        this.resetFormValues();
      }
    },
  },
  created() {
    this.resetFormValues();
  },
  methods: {
    resetFormValues() {
      const fValue = [];
      const fState = [];
      const fError = [];
      this.form.fieldsets.forEach((set, idx) => {
        fValue[idx] = [];
        fState[idx] = [];
        fError[idx] = [];
        set.fields.forEach((field, index) => {
          fValue[idx][index] = '';
          fState[idx][index] = null;
          fError[idx][index] = '';
        });
      });
      this.formValue = fValue;
      this.formState = fState;
      this.formError = fError;
    },
    clickDynFormOk() {
      this.$bvModal.hide('modal-dynamic_form');
    },
    async sendForm() {
      let save = true;
      this.form.fieldsets.forEach((set, idx) => {
        set.fields.forEach((field, index) => {
          let state = true;
          let error = '';
          field.validation.forEach((val) => {
            if (this.validateField(val, idx, index) !== true) {
              state = false;
              error = this.formError[idx][index];
            }
          });
          this.$set(this.formState[idx], index, state);
          this.$set(this.formError[idx], index, error);
        });
      });

      this.form.fieldsets.forEach((set, idx) => {
        set.fields.forEach((field, index) => {
          if (this.formState[idx][index] !== true) {
            save = false;
          }
        });
      });

      if (save) {
        // Send the form
        const input = this.buildFormInput();
        const retval = await sendForm(input);
        if (!retval.has_errors) {
          this.msg = retval.thank_you_message;
          this.$bvModal.show('modal-dynamic_form');
          this.resetForm();
        }
      }
    },
    buildFormInput() {
      let input = `type: ${this.type} ${this.remote_entity_id} field: [`;
      this.form.fieldsets.forEach((set, idx) => {
        set.fields.forEach((field, index) => {
          input += `{ name: "${field.name}", value: "${this.formValue[idx][index]}" },`;
        });
      });
      input = input.slice(0, -1) + ']';
      return input;
    },
    resetForm() {
      this.form.fieldsets.forEach((set, idx) => {
        set.fields.forEach((field, index) => {
          this.$set(this.formValue[idx], index, '');
          this.$set(this.formState[idx], index, null);
          this.$set(this.formError[idx], index, '');
        });
      });
    },
    validateField(val, idx, index) {
      const value = this.formValue[idx][index];
      switch (val.type) {
        case 'required':
          if (!value) return false;
          break;
        case 'alpha_numeric':
          if (!/^[a-zA-Z0-9]+$/.test(value)) return false;
          break;
        // Add other validations as needed
        default:
          return true;
      }
      return true;
    },
    updateFormState(idx, index, { state, error }) {
      this.$set(this.formState[idx], index, state);
      this.$set(this.formError[idx], index, error);
    },
  },
};
</script>
