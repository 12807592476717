<template>
  <div class="filtered-products">
    <div class="loader-box" v-if="isLoading">
      <div class="reverse-spinner"></div>
    </div>
    <div v-else-if="totalProducts === 0">NO Products Found</div>
    <div v-else>
      <ProductsSection :products-list="productList" />
    </div>
  </div>
</template>

<script>
import ProductsSection from "@/esf_kerkrade_vitanatura/core/components/ProductsSection";
// import chunkArray from "@/base/helpers/chunkArray";

export default {
  name: "SearchFilteredProducts",
  components: {
    ProductsSection,
  },
  computed: {
    isLoading() {
      return this.$store.getters["search/getLoadProducts"];
    },
    productList() {
      return this.$store.getters["search/getShowProducts"];
    },
    totalProducts() {
      return this.$store.getters["search/getTotalProducts"];
    },
    // productsChunks() {
    //   return chunkArray(this.$store.getters["search/getShowProducts"], 12);
    // },
  },
};
</script>


