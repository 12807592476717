<template>
  <AppLayout wrapperClass="wishlist">
    <Breadcrumbs />
    <section class="container">
      <div class="title-wrapper">
        <h1 class="title"> Wunschliste </h1>
        <h6 class="sub-title"> Eine Übersicht Ihrer Wunschliste </h6>
      </div>

      <div>
        <!-- Wishlist Empty State -->
        <div v-if="wishListItems.length === 0" class="empty-wishlist">
          <p>Your wishlist is empty. Add some products to it!</p>
        </div>

        <!-- Display wishlist items if available -->
        <div v-else class="product-section-wrapper border-top">
          <div class="product-wrapper row">
            <div class="col-xl-3 col-lg-4 col-sm-6 col-12 mb-40" v-for="(product, index) in wishListItems"
              :key="product.id || index">
              <ProductCard :product="product.product" :isWishlistPage="true" />
            </div>
          </div>
        </div>

        <div>

          <b-link class="btn btn-warning d-block"> {{ $t("Befehl") }}</b-link>
          <span class="h2 title">Zu Ihrer Liste hinzufügen!</span>
        </div>
        <!-- Cross-sell Products Section -->
        <div>


          <div class="product-section-wrapper" v-if="crossSellProducts.length > 4">
            <VueSlickCarousel class="product-wrapper" :dots="false" :arrows="true" :slidesToShow="4"
            
              :slidesToScroll="1" :infinite="true" :responsive="[
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 3
                  }
                },
                {
                  breakpoint: 992,
                  settings: {
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1
                  }
                }
              ]">
              <ProductCard v-for="(product, index) in crossSellProducts" :key="index" class="" :product="product" />
            </VueSlickCarousel>
          </div>
          <div v-else class="product-section-wrapper">
            <div class="product-wrapper row">
              <ProductCard class="col-xl-3 col-lg-4 col-sm-6 col-12 mb-40" v-for="(product, index) in crossSellProducts"  
                :key="index" :product="product" />
            </div>
          </div>
        </div>
      </div>

    </section>
  </AppLayout>
</template>

<script>
import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout";
import Breadcrumbs from "@/esf_kerkrade_vitanatura/core/components/Breadcrumbs";
import ProductCard from "@/esf_kerkrade_vitanatura/core/components/ProductCard";
import VueSlickCarousel from "vue-slick-carousel";


export default {
  name: "Wishlist",
  components: {
    AppLayout,
    Breadcrumbs,
    VueSlickCarousel,
    ProductCard,
  },

  computed: {
    wishListItems() {
      return this.$store.getters["user/getWishlist"];
    },
    crossSellProducts() {
      return this.$store.getters["product_local/getCrossSellSkuArray"];
    },
  },
  watch: {
    wishListItems: {
      handler(newValue) {
        const skus = []
        if (newValue.length > 0) {
          newValue.forEach(element => {
            skus.push(element.product.sku)
          });
          this.loadCrossSellProducts(skus);
        }
      },
      immediate: true,
    },
  },
  methods: {
    async loadCrossSellProducts(skus) {
      try {
        await this.$store.dispatch("product_local/loadCrossSellSkuArray", { skus });
      } catch (error) {
        console.error("Error loading cross-sell products:", error);
      }
    },
  },
  metaInfo() {
    return {
      title: "Wishlist",
    };
  },  
};
</script>
