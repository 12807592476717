<template>
    <b-form-group :id="fieldId" :label="field.label" :label-for="fieldId">
        <component :is="fieldComponent" :class="fieldComponent == 'b-form-select'  ? 'form-control' : ''" :id="fieldId" :name="field.name" :value="field.value"
            :unchecked-value="field.uncheckedValue" :placeholder="field.placeholder"
            :aria-describedby="fieldId + '-feedback'" :options="field.options" :state="state">
        </component>

        <b-form-invalid-feedback :id="fieldId + '-feedback'">
            {{ error }}
        </b-form-invalid-feedback>
    </b-form-group>
</template>

<script>
export default {
    props: {
        field: Object,  // Field data from API
        idx: Number,    // Index of the fieldset
        index: Number,  // Index of the field within the fieldset
        modelValue: [String, Number, Boolean],  // v-model binding for form data
        state: Boolean, // Form field state (valid or invalid)
        error: String,  // Error message
    },
    computed: {
        fieldId() {
            return `set${this.idx}-field${this.index}`;
        },
        fieldComponent() {
            // Dynamically return the appropriate component for each field type
            switch (this.field.type) {
                case 'textarea':
                    return 'b-form-textarea';
                case 'select':
                    return 'b-form-select';
                case 'checkbox':
                    return 'b-form-checkbox';  // Use b-form-checkbox for checkbox inputs
                case 'radio':
                    return 'b-form-radio';
                default:
                    return 'b-form-input';  // Default to b-form-input for other input types
            }
        },
    },
};
</script>