<template>
  <AppLayout wrapperClass="search">
    <Breadcrumbs />
    <div class="container">
      <div class="row">
        <SearchProductFilter class="col-lg-3" />
        <ProductsListingSearch class="col-lg-9" />
      </div>
    </div>
  </AppLayout>
</template>

<script>
import config from "@config";
import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout";
import Breadcrumbs from "@/base/core/components/Breadcrumbs";
import SearchProductFilter from "@/esf_kerkrade_vitanatura/core/components/search/SearchProductFilter";
import ProductsListingSearch from "@/esf_kerkrade_vitanatura/core/components/search/ProductsListingSearch";
import { extractFilters } from "@storefront/core/router/helpers/cleanPath.js";

export default {
  name: "CategoryOverviews",
  components: {
    AppLayout,
    Breadcrumbs,
    SearchProductFilter,
    ProductsListingSearch,
  },
  metaInfo() {
    return {
      title: "Search",
    };
  },
  computed: {
    searchWord() {
      if (typeof this.$route.query.q != "undefined") {
        return this.$route.query.q;
      } else {
        return "";
      }
    },
  },
  watch: {
    searchWord() {
      if (this.searchWord != "") {
        this.$store.dispatch("search/load", { search: this.searchWord });
        const bcrumb = { current: this.$route.query.q, routes: [] };
        this.$store.commit("breadcrumbs/set", bcrumb);
        const { path } = this.$route;
        const query = { ...this.$route.query };
        const pageSize = config.products?.perPage;
        const perPage = this.$store.getters["search/getProductsPerPage"];

        if (perPage != pageSize) query["page-size"] = perPage;
        this.$router.push({ path: path, query: query });
      }
    },
  },
  async mounted() {
    if (this.searchWord != "") {
      const filter = extractFilters(this.$route);
      await this.$store.dispatch("search/load", {
        search: this.searchWord,
        filter: filter,
      });
    }
    const bcrumb = { current: this.$route.query.q, routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  destroyed() {
    this.$store.commit("search/setLoadProducts", false);
  },
};
</script>

